<style lang="scss" scoped>
::v-deep .protect_user_title .text {
	display: block;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	.user_same {
		padding: 0 0.2rem;
		font-size: 0.24rem;
		line-height: 0.5rem;
		text-align: center;
		border-radius: 0.08rem;
		border: 1px solid $color_main;
		color: #fff;
		background-color: $color_main;
	}
	.btns {
		display: flex;
		justify-content: space-between;
		.name_paste {
			margin-right: 0.2rem;
		}
	}
}
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
::v-deep .cell_value {
	color: $font_color_val;
	font-size: 0.24rem;
}
.user_info_cell {
	align-items: center;
	padding-bottom: 20px;
	.van-cell__label {
		height: 0;
		margin-top: 0;
	}
	.van-cell__title {
		flex: auto;
	}
	.van-cell__value {
		display: flex;
		justify-content: flex-end;
		color: #8b9299;
	}
}
.user_icon {
	width: 0.28rem;
	height: 0.28rem;
	background: url(../../../assets/icon/delete.png);
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center;
	margin-right: 0.16rem;
}
.user_icon,
.user_title {
	font-size: 0.28rem;
}
.user_title {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.add_user_btn {
	margin: 0.5rem 0.3rem 0.4rem;
	height: 0.9rem;
	border: 1px dashed #dddddd;
	display: flex;
	align-items: center;
	justify-content: center;
	.icon {
		width: 0.23rem;
		height: 0.22rem;
		background: url(../../../assets/icon/add.png);
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center;
	}
	.text {
		font-size: 0.3rem;
		color: $color_main;
		margin-left: 0.2rem;
	}
}
::v-deep .analysis_toast {
	z-index: 3003 !important;
}

.error_name_dialog {
	.statistics {
		padding: 0.3rem;
		font-weight: bold;
		font-size: 14px;
	}
	.num {
		font-size: 20px;
	}
	.error_num {
		color: $danger;
	}
	.title {
		padding: 0 0.3rem;
		font-size: 14px;
		line-height: 14px;
		position: relative;
		top: 6px;
		z-index: 1;
	}
	.error {
		color: $danger;
		font-size: 12px;
		line-height: 14px;
		position: relative;
		top: -6px;
		padding: 0 0.3rem;
		margin-bottom: 0.2rem;
	}
}

.error {
	color: $danger;
	font-size: 12px;
	line-height: 14px;
	position: relative;
	top: -6px;
	padding: 0 0.3rem;
	margin-bottom: 0.2rem;
}
.user_list_error_text {
	color: $danger;
}
::v-deep .name_paste_dialog {
	z-index: 3002 !important;
	.name_textarea {
		textarea {
			max-height: 50vh;
			overflow-y: auto;
			// background-color: $line_color;
			border: 1px solid $line_color;
			padding: 0.08rem;
		}
	}
	.van-cell::after {
		border: none;
	}
	.btns {
		display: flex;
		justify-content: space-around;
		padding-bottom: 10px;
		padding: 0 0.3rem 10px;
		button {
			width: 42%;
		}
	}

	.van-dialog__cancel {
		color: #323233;
		background-color: #fff;
		background: #fff;
		border: 1px solid #ebedf0;
		border-radius: 2px;
		margin-right: 0.1rem;
	}
	.van-dialog__confirm {
		border-radius: 2px;
		margin-left: 0.1rem;
	}
}
.cell_box {
	overflow: auto;
	max-height: 40vh;
}
</style>

<template>
	<div class="assureds">
		<active-title class="protect_user_title">
			<span v-text="`被保人信息(${userListHasPrice.length}/200)`"></span>
			<div class="btns">
				<div class="user_same name_paste" v-if="paste || paste === ''" @click="openNamePasteDialog">名单粘贴</div>
				<div class="user_same" v-if="userListHasPrice.length == 0 && userSameBtn && (sameInsure || sameInsure === '')" @click="userSame">同投保人</div>
			</div>
		</active-title>
		<van-cell-group :border="false" v-show="userListHasPrice.length" class="cell_box">
			<van-cell v-for="(item, index) in userListHasPrice" :key="index" @click="editUser(item)" title-class="cell_title" value-class="cell_value" class="user_info_cell" :border="true" is-link>
				<span class="user_icon" slot="icon" @click.stop="deleteUser(item.id)"></span>
				<span class="user_title" slot="title">
					<div>
						<span v-text="item.userName"></span>
						<!-- <div>¥ {{item.price}}</div> -->
					</div>
					<div>
						<span v-text="item.cardNum"></span>
						<!-- <div style="text-align:right;" v-text="item.job">装修工</div> -->
					</div>
				</span>
				<!-- <span slot="default">
                    <span>装修工</span>
                </span> -->
				<span slot="label" class="user_list_error_text">
					<span v-if="item.isError">信息中有误</span>
					<span v-if="item.isError && item.isRepeat">，</span>
					<span v-if="item.isRepeat">证件号码重复</span>
				</span>
			</van-cell>
		</van-cell-group>

		<div class="add_user_btn" @click="addUser">
			<span class="icon"></span>
			<span class="text">新增被保险人</span>
		</div>
		<!-- <div class="add_user_btn" v-if="assuredBook || assuredBook === ''" @click="historyAssureds">
            <span class="icon"></span>
            <span class="text">常用被保险人</span>
        </div> -->

		<!-- TODO:名单粘贴 -->
		<van-dialog className="name_paste_dialog" v-model="namePasteDialogShow" :show-confirm-button="false" title="名单粘贴">
			<van-field
				class="name_textarea"
				v-model.trim="nameString"
				rows="8"
				autosize
				type="textarea"
				border
				autofocus
				placeholder="请按规则输入名单数据，姓名中间存在多个空格会解析失败。
正确规则如：
	张三  身份证  110101199901017917
	张红  女  E12345678  1980-01-10"></van-field>
			<div class="btns">
				<van-button type="default" @click="namePasteDialogShow = false">取消</van-button>
				<van-button type="info" @click="analysisNameStr" :disabled="!nameString" :loading="analysisIng" loading-text="解析中...">确定</van-button>
			</div>
		</van-dialog>

		<!-- TODO:错误提示 -->
		<van-dialog className="name_paste_dialog error_name_dialog" v-model="namePasteErrorDialogShow" :show-confirm-button="false" title="错误提示">
			<p class="statistics">
				解析
				<span class="total_num num" v-text="nameSuccessLength + nameErrorLength">0</span>
				条数据，成功
				<span class="success_num num" v-text="nameSuccessLength">0</span>
				条，失败
				<span class="error_num num" v-text="nameErrorLength">0</span>
				条
			</p>
			<p class="title">解析失败数据：</p>
			<van-field class="name_textarea" v-model="errorNameString" rows="8" autosize type="textarea"></van-field>
			<p class="error">可能的错误原因：输入不规范、证件号码错误</p>
			<div class="btns">
				<van-button type="default" @click="namePasteErrorDialogShow = false">关闭</van-button>
				<van-button type="info" ref="copyBtn" :data-clipboard-text="errorNameString">复制错误信息</van-button>
			</div>
		</van-dialog>
	</div>
</template>

<script>
import { analysisNameString } from '@/request/api';
import { http_addAssured } from '@/request/sundry';
import { Cell, CellGroup, Button, Toast, Dialog, Field, DatetimePicker } from 'vant';
import ClipboardJS from 'clipboard';
import bus from '@/components/bus';
import Mtils from 'mtils';
import { cardTypeMap2, cardTypeMap } from '@/config/fixedParams';
import { cardType } from '@/config/fixedParams';
export default {
	name: 'assureds3', // 被保人列表形式
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Button.name]: Button,
		[Toast.name]: Toast,
		[Dialog.Component.name]: Dialog.Component,
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
	},
	props: {
		// 名单粘贴
		paste: {
			type: [Boolean, String],
			required: false,
			default: false,
		},

		// 同投保人按钮
		sameInsure: {
			type: [Boolean, String],
			required: false,
			default: false,
		},

		// 常用被保险人列表
		assuredBook: {
			type: [Boolean, String],
			required: false,
			default: false,
		},

		// 保险公司id，为了获取职业类别
		companyId: {
			type: [Number, String],
			required: true,
		},

		userSameBtn: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	data() {
		return {
			// 被保险人
			userList: [],

			rules: {},

			// TODO:名单粘贴弹出层
			namePasteDialogShow: false,
			nameString: '',
			analysisIng: false,

			namePasteErrorDialogShow: false,
			nameSuccessLength: 0,
			nameErrorLength: 0,
			errorNameString: '',
		};
	},
	computed: {
		userListHasPrice() {
			// console.log(this.$store.state.priceList,'this.$store.state.priceList999');
			// console.log( this.$store.state.sundryViewTime,' this.$store.state.sundryViewTime888');
			// console.log( this.userList,' this.userList666666666666');
			// console.log(this.$store.state.priceList,'this.$store.state.priceList999');
			// return this.userList.map(item => {
			//     let priceList = this.$store.state.priceList.filter(p => p.viewTime === this.$store.state.sundryViewTime && p.eleUse1 === item.jobDict.codeData)
			//     // let priceList = this.$store.state.priceList.filter(p => p.viewTime === this.$store.state.sundryViewTime)
			//     if (priceList.length) {
			//         item.priceInfo = priceList[0]
			//         item.price = Number(priceList[0].price)
			//     }
			//     return item
			// })
			return this.userList;
		},
	},
	watch: {
		userListHasPrice: function (v) {
			// let totalPrice = 0
			// if(v.length) {
			//     v.forEach(item => {
			//         totalPrice += item.price
			//     });
			// }
			// v.forEach(item => {
			//     totalPrice += item.price
			// });
			// this.$emit('change', totalPrice)
			// this.$emit('change', this.userList)
			// this.$emit('changeUserList', this.userList)
			this.$emit('changeUserList', v);
		},
		deep: true,
		immediate: true,
	},
	created() {
		this.initBus();
	},
	beforeDestroy() {
		this.clearBus();
	},
	methods: {
		// 获取数据
		getData() {
			let data = this.userListHasPrice.map(item => {
				let assured = Object.assign(
					{
						cardTypeCode: cardTypeMap2[item.cardType],
						// relationCode: relationMap[item.insureRelation]
					},
					item,
				);
				assured.birthday = assured.birthday.replace(/\//g, '-');
				return assured;
			});
			if (data.length) {
				return Promise.resolve(data);
			} else {
				this.analysisToast('请添加被保险人', 'fail');
				return Promise.reject();
			}
		},

		// 删除被保人
		deleteUser(id) {
			let ids = this.userList.map(item => item.id);
			let index = ids.indexOf(id);
			this.userList.splice(index, 1);

			// 执行重复检查
			this.validateRepeat();
		},

		// 检查重复的证件号码，该方法会标记出所有的重复信息，比如：有三条一模一样的证件号码，会把这三条全标记出来
		validateRepeat(cb) {
			let repeatInfos = [];
			let idNumList = this.userList.map(row => row.cardNum);
			let repeatIds = [];
			for (let i = 0; i < idNumList.length; i++) {
				const num = idNumList[i];
				let otherNums = idNumList.slice(i + 1);
				let repeatIndex = otherNums.indexOf(num);
				if (repeatIndex !== -1) {
					repeatIds.push(num);
					this.userList[i].isRepeat = true;
					this.userList[i + repeatIndex + 1].isRepeat = true;
					repeatInfos.push(this.userList[i], this.userList[i + repeatIndex + 1]);
				} else {
					if (!repeatIds.includes(num)) {
						this.$set(this.userList[i], 'isRepeat', false);
					}
				}
			}
			if (cb) {
				cb(repeatInfos);
			}
		},

		// 新增新增被保险人-打开页面
		addUser() {
			this.$store.commit('set_sundryCreateOrderRoutePath', 'HT_groupaccident');
			this.$router.push({
				path: 'addUser3',
				query: Object.assign(this.$route.query, { type: 'add', companyId: this.companyId }),
			});
		},

		// 编辑被保人信息
		editUser(info) {
			this.$router.push({
				path: 'addUser3',
				query: Object.assign(this.$route.query, { type: 'edit', companyId: this.companyId }, { userInfo: JSON.stringify(info) }),
			});
		},

		setUser(info) {
			this.setUserInfo(info);

			// 执行校验方法
			this.chekUserInfoList();

			// 执行重复检查
			this.validateRepeat();

			// 刷新DOM
			this.$forceUpdate();
		},

		// 新增被保险人-设置数据 idCompensation: id补偿字符，因为循环太快，在1ms中可以计算很多数据，id会重复
		setUserInfo(info, idCompensation = undefined) {
			if (!info.id) {
				info.id = String(Date.now());
				if (idCompensation !== undefined) {
					info.id += idCompensation;
				}
				this.userList.push(info);
			} else {
				this.userList.forEach(item => {
					if (item.id === info.id) {
						Object.assign(item, info);
					}
				});
			}
			// 存储被保险人
			// this.saveAssured(info)
		},

		// saveAssured(info) {
		//     let send = {
		//         "type": 1,
		//         "name": info.userName,
		//         "certificateType": info.cardType,
		//         "certificateContent": info.cardNum,
		//         "birthday": info.birthday,
		//         // "profession": info.job,
		//         "sex": info.sex,
		//         "mobile": info.phone,
		//         "assEmail": info.email,
		//         // "relation": info.insureRelation,
		//         // "buyNum": info.buyNum,
		//         // "job": info.jobDict.codeData,               // 本级职业等级
		//         // "parentCode": info.jobDict.parentCode,      // 上级职业代码
		//         // "profCode": info.jobDict.profCode,          // 本级职业代码
		//         // "profName": info.jobDict.profName,          // 本级职业名称
		//         // "rsvp1": "",
		//         // "rsvp2": "",
		//         // "rsvp3": ""
		//     }
		//     http_addAssured(send).then(res => {
		//     })
		// },

		// 手动校验被保险人信息
		chekUserInfoList() {
			this.userList.forEach(dict => {
				dict.isError = !this.checkUserInfo(dict);
			});
		},

		// 校验被保险人信息方法，有错误即停止检查，并返回false。完全正确返回true
		checkUserInfo(info) {
			let result = true;
			outer: for (const key in info) {
				if (Object.hasOwnProperty.call(info, key) && Object.hasOwnProperty.call(this.rules, key)) {
					const val = info[key];
					let ruleList = this.rules[key];

					// 注意：break无法跳出foreach
					for (let i = 0; i < ruleList.length; i++) {
						const rule = ruleList[i];
						// 必填校验
						if (Object.hasOwnProperty.call(rule, 'required') && key !== 'phone') {
							if (rule.required && !val && val !== 0) {
								result = false;
								break outer;
							}
						}

						// 自定义方法校验
						if (Object.hasOwnProperty.call(rule, 'validator')) {
							if (rule.validator && (val || val === 0)) {
								if (rule.validator(val, true, info) !== true) {
									result = false;
									break outer;
								}
							}
						}
					}
				}
			}
			return result;
		},

		// 初始化bus挂载方法
		initBus() {
			bus.$on('receiveInsureData', this.receiveInsureData);
			bus.$on('addUser3', this.setUser);
		},

		// 销毁bus方法
		clearBus() {
			bus.$off('receiveInsureData');
			bus.$off('addUser3');
		},

		// 接收投保人信息
		receiveInsureData(dict) {
			// console.log(dict,'dict');
			// 同投保人，把投保人信息填入被保人中
			if (Mtils.validation.isIdCard(dict.cardNum)) {
				if (!this.userList.some(item => item.cardNum === dict.cardNum)) {
					let idInfo = this.$base.getInfoFromIdNumber(dict.cardNum);
					let userInfo = {
						// insureRelation: '本人',
						userName: dict.userName,
						cardType: '身份证',
						cardNum: dict.cardNum,
						birthday: idInfo.birthday,
						sex: idInfo.sex,
						phone: dict.phone,
						email: dict.email,
						buyNum: '1',
						id: undefined,
						// job: '',
					};
					this.$router.push({
						path: 'addUser3',
						query: Object.assign(this.$route.query, { type: 'edit', companyId: this.companyId }, { userInfo: JSON.stringify(userInfo) }),
					});
				}
			} else {
				let userInfo = {
					userName: dict.userName,
					cardType: cardTypeMap[dict.cardType],
					cardNum: dict.cardNum,
					birthday: dict.birthday,
					sex: dict.sex,
					phone: dict.phone,
					email: dict.email,
					buyNum: '1',
					id: undefined,
				};

				this.$router.push({
					path: 'addUser3',
					query: Object.assign(this.$route.query, { type: 'edit', companyId: this.companyId }, { userInfo: JSON.stringify(userInfo) }),
				});
			}
		},

		// 同投保人
		userSame() {
			bus.$emit('getInsureData');
		},

		// 打开名单粘贴弹窗
		openNamePasteDialog() {
			this.nameString = '';
			this.namePasteDialogShow = true;
		},

		analysisToast(msg, type = 'text') {
			Toast({
				message: msg,
				className: 'analysis_toast',
				getContainer: '#create_order',
				type,
			});
		},

		// 解析名单
		analysisNameStr() {
			let rows = this.nameString.split('\n');

			// 200条数据检测
			if (rows.length > 200) {
				this.analysisToast('最大支持200条数据，请修改输入的数据', 'fail');
			}

			// 去除姓名中间的空格，只支持一个空格
			let nameString = this.removeIndex1Space(rows);
			this.analysisIng = true;
			analysisNameString(nameString)
				.then(res => {
					this.analysisIng = false;
					this.nameSuccessLength = res.profList.length;
					this.nameErrorLength = res.errorList.length;

					// 解析结果中有错误信息
					if (this.nameErrorLength) {
						this.errorNameString = '';
						res.errorList.forEach(itemStr => {
							this.errorNameString += itemStr + '\n';
						});
						this.namePasteErrorDialogShow = true;

						// 初始化复制事件
						this.$nextTick(this.copyEventInit);
					}

					// 最多只解析200条信息
					let list = res.profList;
					if (this.userList.length + list.length > 200) {
						list.splice(200 - this.userList.length);
					}

					// 解析结果到被保险人名单中
					if (list.length) {
						list.forEach((item, index) => {
							this.setUserInfo(
								{
									userName: String(item.name).replace(' ', ''),
									// cardType: cardType.filter(type => type.value === item.certificateType)[0].label,
									cardType: cardType.filter(type => type.value === item.certificateType)[0].label,
									cardNum: item.certificateContent.toLocaleUpperCase(),
									birthday: item.birthday,
									sex: item.sex,
									phone: item.mobile,
									id: undefined,
								},
								index,
							);
						});

						// 执行校验方法
						this.chekUserInfoList();

						// 执行重复检查
						this.validateRepeat();
					}

					this.namePasteDialogShow = false;
					// console.log(this.namePasteDialogShow,this.namePasteDialogShowthis.namePasteDialogShow);
				})
				.catch(err => {
					if (err.message === '用户未登录') {
						this.$router.push({
							path: 'login',
							query: {
								pid: this.pid,
							},
						});
					}
				});
		},

		// 把textarea中取的值，处理每行前2个字符中的空格（为了处理姓名中间的空格），并返回textarea格式的字符串
		removeIndex1Space(rows) {
			let result = '';
			rows.forEach(row => {
				let trimRow = row.trim();

				// 去除特殊的字符
				trimRow = this.removeSpecialString(trimRow);

				if (trimRow.slice(0, 2).includes(' ')) {
					trimRow = trimRow.replace(' ', '');
				}
				result = result + trimRow + '\n';
			});
			return result;
		},

		// 去除特殊字符
		removeSpecialString(str) {
			let strCode = encodeURI(str);
			let specialCodeList = ['%E2%80%8B'];
			specialCodeList.forEach(code => {
				strCode = strCode.replace(code, '');
			});
			return decodeURI(strCode);
		},

		// 初始化复制事件
		copyEventInit() {
			let clipboard = new ClipboardJS(this.$refs.copyBtn);
			clipboard.on('success', e => {
				this.analysisToast('复制成功', 'success');
				e.clearSelection();
			});

			clipboard.on('error', e => {
				this.analysisToast('复制成功', 'fail');
			});
		},

		// 常用被保险人
		historyAssureds() {
			console.log('打开常用被保险人列表');
			// 打开常用被保险人列表
			this.$router.push('assuredBook2');
		},
	},
};
</script>
