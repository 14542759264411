<style lang="scss" scoped>
.main_title {
	font-size: 0.3rem;
	color: $font_color_main;
	font-weight: bold;
}
.product_name {
	padding: 0.4rem 0.3rem;
	line-height: 0.3rem;
}
</style>

<template>
	<div class="upfitter">
		<header-nav :hasLogout="!Boolean(userDesc)">{{ navText }}</header-nav>

		<protect-limit2 ref="protect" :productId="productId" :planId="planId" plan @changeTime="protectLimitChange" time :isProfess="true" @changejobCheck="changejobCheck">
			<span slot="title">投保信息</span>
		</protect-limit2>
		<split-line></split-line>

		<insure-form2 ref="insure" :fixed="false" :fill="!Boolean(userDesc)" :isProfess="true" :companyId="companyId" @changeUserType="changeUserType"></insure-form2>
		<split-line></split-line>

		<!-- 被保人信息 -->
		<assureds3 ref="assured" :sameInsure="true" :userSameBtn="isuserSameBtn" :assuredBook="!Boolean(userDesc)" :paste="true" :companyId="companyId" @changeUserList="chaangeAssured"></assureds3>
		<split-line></split-line>

		<!-- 条款 -->
		<clause v-model="check" :productId="productId"></clause>

		<page-footer></page-footer>
		<price-buy-btn :price="price" @buy="buy"></price-buy-btn>

		<loading :show="loading">提交中</loading>
	</div>
</template>

<script>
import { Toast } from 'vant';
import splitLine from '../components/splitLine.vue';
import protectLimit2 from '../components/protectLimit2.vue';
import insureForm2 from '../components/insureForm2.vue';
import subjectForm from '../components/subjectForm.vue';
import clause from '../components/clause.vue';
import assureds3 from '../components/assureds3.vue';
import { http_getPalns } from '@/request/sundry';
import headerNav from '../components/headerNav.vue';
import { submitBill } from '@/request/api';
import { cardType } from '@/config/fixedParams';
export default {
	name: 'HT_groupaccident', // “耀安心”团体人身意外伤害保障
	components: {
		splitLine,
		protectLimit2,
		insureForm2,
		subjectForm,
		assureds3,
		clause,
		headerNav,
		[Toast.name]: Toast,
	},
	data() {
		return {
			pid: '',
			userDesc: '',
			navText: '“耀安心”团体人身意外伤害保障',
			productId: '',
			planId: '',

			scrollY: 0,

			check: false,

			companyId: '', // 保险公司id

			priceId: '',
			protectInfo: {}, // 保障期限信息

			totalPrice: 0, // 总价
			loading: false,

			isuserSameBtn: true, //是否显示同投保人按钮
			userList: [], //被保人
			professObj: '', //职业类型
			priceObject: '', //价格信息
		};
	},
	computed: {
		price: function () {
			return this.$base.floatPrice(this.totalPrice);
		},
	},
	created() {
		this.init();
		this.getPlain();
	},
	beforeRouteLeave(to, from, next) {
		if (to.path.includes('/sundry/product')) {
			khs.stop();
		}
		this.scrollY = document.querySelector('html').scrollTop;
		next();
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.$nextTick(() => {
				document.querySelector('html').scrollTop = vm.scrollY;
			});
		});
	},
	methods: {
		// 改变证件类型 来判断有没有同投保人按钮
		changeUserType(e) {
			if (e == '个人') {
				this.isuserSameBtn = true;
			} else {
				this.isuserSameBtn = false;
			}
		},

		back() {
			this.$router.go(-1);
		},

		init() {
			let query = this.$route.query;
			this.pid = query.pid;
			this.userDesc = query.user;
			this.navText = `${query.pName}-${query.planName}`;
			this.productId = query.productId;
			this.planId = query.plainId;
			this.companyId = query.companyId;

			this.priceId = query.priceId;
		},

		// 去登录页
		toLoginPage(err) {
			if (err.message === '用户未登录') {
				this.$router.push({
					path: 'login',
					query: {
						pid: this.pid,
						user: this.userDesc,
					},
				});
			}
		},

		// 获取计划
		getPlain() {
			if (this.$store.state.priceList.length) {
				return;
			}
			http_getPalns(this.productId)
				.then(res => {
					this.$store.commit('set_priceList', res.filter(item => String(item.id) === String(this.planId))[0].productPriceList);
				})
				.catch(err => {
					if (err.message === '用户未登录') {
						this.$router.push('login');
					}
				});
		},

		// 保障期限改变
		protectLimitChange() {
			this.totalPrice = 0;
			if (this.professObj && this.userList.length) {
				let priceList = this.$store.state.priceList.filter(item => item.viewTime == this.$store.state.sundryViewTime && item.eleUse1 === this.professObj.codeData);
				if (priceList.length) {
					this.priceObject = priceList[0];
					this.totalPrice += Number(this.userList.length * this.priceObject.price).toFixed(2);
				}
			}
		},

		// 改变被保人数
		chaangeAssured(list) {
			this.totalPrice = 0;
			this.userList = list;
			if (this.professObj) {
				let priceList = this.$store.state.priceList.filter(item => item.viewTime == this.$store.state.sundryViewTime && item.eleUse1 === this.professObj.codeData);
				if (priceList.length) {
					this.priceObject = priceList[0];
					this.totalPrice += Number(this.userList.length * this.priceObject.price).toFixed(2);
				}
			}
		},

		// 改变职业
		changejobCheck(dict) {
			this.professObj = dict;
			this.totalPrice = 0;
			if (this.userList.length) {
				let priceList = this.$store.state.priceList.filter(item => item.viewTime == this.$store.state.sundryViewTime && item.eleUse1 === this.professObj.codeData);
				if (priceList.length) {
					this.priceObject = priceList[0];
					this.totalPrice += Number(this.userList.length * this.priceObject.price).toFixed(2);
				}
			}
		},

		async buy() {
			if (!this.check) {
				Toast('请先勾选条款');
				return false;
			}

			// 获取投保信息
			let protectData = await this.$refs.protect.getData();
			// 获取投保人信息
			let insureData = await this.$refs.insure.getData();
			// 获取被保险人
			let assured = await this.$refs.assured.getData();
			let assuredList = assured.map(item => {
				return {
					name: item.userName,
					certificateType: cardType.filter(card => card.label === item.cardType)[0].value,
					certificateContent: item.cardNum,
					birthday: this.$base.submitDateFormatter(item.birthday),
					sex: item.sex,
					mobile: item.phone,
				};
			});

			let extentionList = [];
			extentionList.push({
				colName: 'CXZT_occupationType',
				colValue: protectData.jobObject.codeData,
			});
			if (protectData.jobObject.profName) {
				let newName = protectData.jobObject.profName.split('(');
				if (newName.length) {
					protectData.jobObject.profName = newName[0];
				}
			}
			extentionList.push({
				colName: 'CXZT_occupationName',
				colValue: protectData.jobObject.profName,
			});

			let send = {
				assuredList, // 被保人
				insurer: {
					insBirthday: this.$base.submitDateFormatter(insureData.birthday),
					insCredentials: insureData.cardNum,
					insCredentialsType: insureData.cardType,
					insEmail: insureData.email,
					insName: insureData.userName,
					insPhone: insureData.phone,
					insSex: insureData.sex,
					insType: insureData.userType == '企业' ? 2 : 1,
				},
				extentionList: extentionList,
				insBill: {
					enableDate: protectData.start,
					disEnableDate: this.$base.submitDateFormatter(protectData.end, 2), //终保日期
					id: '',
					insureType: '2', //出单方式
					proId: this.productId, //产品ID
					remark: '',
					platform: 'H5',
					assuredSendMsg: 1,
				},
				optType: '2',
				productPriceVo: {
					comboId: this.planId,
					proId: this.productId,
					viewTime: this.priceObject.viewTime,
					eleView1: this.priceObject.eleView1,
					eleUse1: this.priceObject.eleUse1,
					eleView2: '',
					priceId: this.priceObject.priceId,
					sex: '',
					viewAgeBelong: '',
					insureTime: this.priceObject.insureTime,
					insureTimeUnit: this.priceObject.insureTimeUnit,
				},
			};
			this.submit(send);
		},

		// 提交订单
		submit(send) {
			this.loading = true;
			submitBill(send)
				.then(res => {
					khs.finish(res.data.id);
					this.$store.commit('set_billId', res.data.id);
					this.$router.push({
						name: 'orderPay',
						params: {
							isRedict: false,
						},
					});
				})
				.catch(err => {
					console.log(err);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
