<style lang="scss" scoped>
.fixed_insure {
	margin-left: 20px;
}
::v-deep .no_link .van-icon-arrow {
	color: #fff;
}
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
</style>

<template>
	<div class="insure_form">
		<active-title>
			<slot name="title">投保人信息</slot>
			<template v-if="fixed || fixed === ''" #custom>
				<van-checkbox class="fixed_insure" icon-size="16px" v-model="isFixedInsure" shape="square">固定投保人</van-checkbox>
			</template>
		</active-title>
		<van-form ref="form" input-align="right" error-message-align="right" :scroll-to-error="true">
			<van-cell-group :border="false">
				<van-field label="投保人类型" :value="insureInfo.userType" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" class="no_link" placeholder="请选择投保人类型"  />
				<!-- <van-field label="投保人类型" :value="insureInfo.userType" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" class="no_link" placeholder="请选择投保人类型" @click="userTypePop = true" /> -->

				<template v-if="insureInfo.userType == '企业' || insureInfo.userType == '团体'">
					<van-field label="企业名称" v-model="insureInfo.userName" :formatter="nameFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写企业名称" :rules="rules.userName" />
					<van-field label="证件类型" :value="insureInfo.cardType" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly class="no_link" clickable placeholder="请选择证件类型" :rules="rules.cardType"  />
					<!-- <van-field label="证件类型" :value="insureInfo.cardType" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable placeholder="请选择证件类型" :rules="rules.cardType" @click="cardTypePop = true" /> -->
					<van-field label="统一社会信用代码" v-model="insureInfo.cardNum" :formatter="idCardNoFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="社会统一信用代码" :rules="rules.creditCode" />
				</template>
				<template v-else>
					<!-- <van-field label="投保人类型" :value="insureInfo.userType"  label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker"  class="no_link"  placeholder="请选择投保人类型" /> -->
					<van-field label="投保人名称" v-model="insureInfo.userName" :formatter="nameFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请输入投保人名称" :rules="rules.userName" />
					<van-field label="证件类型" :value="insureInfo.cardType" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable placeholder="请选择证件类型" :rules="rules.cardType" @click="cardTypePop = true" />
					<van-field label="证件号码" v-model="insureInfo.cardNum" :formatter="idCardNoFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请输入正确证件号码" :rules="rules.cardNum" />
					<van-field label="出生日期" key="birthday" :value="insureInfo.birthday" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable placeholder="请选择出生日期" :rules="rules.birthday" @click="birthdayPop = true" />
					<van-field label="性别" key="sex" :value="insureInfo.sex" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable placeholder="请选择性别" :rules="rules.sex" @click="sexPop = true" />
				</template>
				<van-field label="手机号码" key="phone" v-model="insureInfo.phone" :formatter="mobileFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写投保人手机号码" :rules="rules.phone" />
				<!-- <van-field label="联系地址" v-model="insureInfo.address" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写投保联系地址" :rules="rules.address" /> -->
				<van-field key="email" label="邮箱" v-model="insureInfo.email" :formatter="emailFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="接收电子保单邮件" />
				<!-- <van-field
                    v-if="isProfess"
                    label="职业类别"
                    :value="insureInfo.job"
                    label-class="cell_title"
                    value-class="cell_value"
                    :border="false"
                    is-link
                    readonly
                    clickable
                    name="picker"
                    placeholder="请选择职业类别"
                    :rules="rules.job"
                    @click="jobPop = true"
                /> -->
			</van-cell-group>
		</van-form>

		<!-- 投保类型 -->
		<van-popup v-model="userTypePop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="投保类型" show-toolbar value-key="value" :visible-item-count="3" :columns="insureUserTypeList" @confirm="userTypeCheck" @cancel="userTypePop = false" />
		</van-popup>
		<!-- 证件类型 -->
		<van-popup v-model="cardTypePop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="证件类型" show-toolbar value-key="label" :visible-item-count="3" :columns="cardTypeList" @confirm="cardTypeCheck" @cancel="cardTypePop = false" />
		</van-popup>
		<!-- 出生日期 -->
		<van-popup v-model="birthdayPop" position="bottom" :style="{ height: '30vh' }">
			<van-datetime-picker type="date" v-model="currentDate" title="选择年月日" @cancel="birthdayPop = false" @confirm="birthdayCheck" visible-item-count="3" :min-date="birthdayMinDate" />
		</van-popup>
		<!-- 性别 -->
		<van-popup v-model="sexPop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="性别" show-toolbar value-key="label" :visible-item-count="3" :columns="sexList" @confirm="sexCheck" @cancel="sexPop = false" />
		</van-popup>
		<!-- 职业类别 -->
		<!-- <van-popup v-model="jobPop" position="bottom">
            <van-cascader v-model="jobType" :options="jobPopList" :field-names="jobSourceMap" @finish="jobCheck" @close="jobPop = false" >
                <template #title>
                    <div>
                        <span>职业类别</span>
                        <span class="warn">(请如实选择职业，否则存在拒赔风险)</span>
                    </div>
                </template>
            </van-cascader>
        </van-popup> -->
	</div>
</template>

<script>
import { Cell, CellGroup, Popup, Picker, Calendar, Field, DatetimePicker, Checkbox, ActionSheet, Button, CountDown, Form, Toast, Dialog, Cascader } from 'vant';
import regular from '@/assets/js/regular';
import Mtils from 'mtils';
import bus from '@/components/bus';
import { insureUserTypeMap, cardTypeMap2 } from '@/config/fixedParams';
import { http_getLastInsured, http_getInsProfessionCodeListByCompanyId } from '@/request/sundry';
// import { http_getJobList, http_getInsProfessionCodeListByCompanyId } from "@/request/sundry";
// import { insureUserType } from "@/config/fixedParams";
import { cardType, groupCardType, sexs, insureUserType } from '@/config/fixedParams';
export default {
	name: 'insureForm2', // 投保信息
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Calendar.name]: Calendar,
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Checkbox.name]: Checkbox,
		[ActionSheet.name]: ActionSheet,
		[Button.name]: Button,
		[CountDown.name]: CountDown,
		[Form.name]: Form,
		[Toast.name]: Toast,
		[Dialog.Component.name]: Dialog.Component,
		[Cascader.name]: Cascader,
	},
	props: {
		// 是否显示“固定投保人”
		fixed: {
			type: [Boolean, String],
			required: false,
			default: false,
		},

		// 是否自动填充投保人,暂时只能有一个页面拥有此功能，因为投保人信息可能不同，不同的投保人信息不能完美的填充到表单中
		fill: {
			type: Boolean,
			required: false,
			default: false,
		},

		// 企业模式
		isCompany: {
			type: [Boolean, String],
			required: false,
			default: false,
		},

		// 是否有职业（华泰团体意外）
		isProfess: {
			type: Boolean,
			required: false,
			default: false,
		},
		companyId: {
			type: [Number, String],
			required: false,
			default: '',
		},
	},
	data() {
		return {
			isFixedInsure: false,

			// 投保人信息
			insureInfo: {
				cardType: '统一社会信用代码', // 证件类型
				userType: '企业',
				userName: '',
				cardNum: '',
				phone: '',
				// address: '',
				email: '',
				job: '',
				jobObject: '',
				sex: '',
				birthday: '',
			},

			rules: {
				userName: [{ required: true }, { pattern: /^[a-zA-Z()（）·]+$|^[\u4e00-\u9fa5()（）·]+$/, message: '只能输入中文或英文以及()·' }, { pattern: /[a-zA-Z]{4,}|[\u4e00-\u9fa5]{2,}/, message: '至少2个中文或4个英文字母' }, { validator: this.nameCheck, message: '·不能出现在首尾' }],
				cardNum: [{ required: true }, { validator: this.cardNumCheck, message: '证件号码有误' }],
				phone: [{ required: true }, { validator: this.phoneCheck, message: '请填写正确的手机号码' }],
				email: [{ required: false }, { validator: this.emailCheck, message: '请输入正确的邮箱地址' }],
				sex: [{ required: true, message: '请选择性别', trigger: 'onChange' }],
				birthday: [{ required: true, message: '请选择出生日期', trigger: 'onChange' }],
				creditCode: [{ required: true }, { validator: this.creditCode, message: '证件号码有误' }],
				job: [{ required: true, message: '请选择职业', trigger: 'onChange' }],
				cardType: [{ required: true, message: '请选证件类型', trigger: 'onChange' }],
			},
			jobPop: false,
			jobType: '',
			jobPopList: [],
			jobSourceMap: {
				text: 'profName',
				value: 'profCode',
				children: 'children',
			},

			userTypePop: false,
			insureUserTypeList: [],
			cardTypeList: [],
			cardTypePop: false,
			birthdayPop: false,
			birthdayMinDate: new Date('1900/01/01'),
			sexPop: false,
			sexList: [],
			currentDate: new Date('1990/01/01'),
		};
	},
	created() {
		this.fillForm();
		this.initBus();
		// this.getJobDict()
		this.insureUserTypeList = insureUserType;
		this.sexList = sexs;
	},
	beforeDestroy() {
		this.clearBus();
	},
	methods: {
		userTypeCheck(val) {
			if (val) {
				this.insureInfo.userType = val.value;
				if (val.value == '企业') {
					this.cardTypeList = groupCardType;
					this.insureInfo.cardType = '统一社会信用代码';
				} else {
					this.cardTypeList = cardType;
					this.insureInfo.cardType = '身份证';
				}
				// this.insureInfo.cardType = ''
				this.insureInfo.cardNum = '';
				this.userTypePop = false;
				this.$emit('changeUserType', val.value);
			} else {
				if (this.insureInfo.userType == '企业') {
					this.cardTypeList = groupCardType;
					this.insureInfo.cardType = '统一社会信用代码';
				} else {
					this.cardTypeList = cardType;
					this.insureInfo.cardType = '身份证';
				}
				this.$emit('changeUserType', this.insureInfo.userType);
			}
			this.insureInfo.cardNum = '';
			this.insureInfo.sex = '';
			this.insureInfo.birthday = '';
			// this.insureInfo.userType = val.value
			// if(val.value == '企业') {
			//     this.cardTypeList = groupCardType
			//     this.insureInfo.cardType = '统一社会信用代码'
			// }else {
			//     this.cardTypeList = cardType
			//     this.insureInfo.cardType = '身份证'
			// }
			// // this.insureInfo.cardType = ''
			// this.insureInfo.cardNum = ''
			// this.userTypePop = false
			// this.$emit('changeUserType',val.value)
		},
		// 性别确认
		sexCheck(val) {
			// console.log(val);
			this.insureInfo.sex = val.value;
			this.sexPop = false;
		},
		// 出生日期确认
		birthdayCheck(val) {
			this.insureInfo.birthday = this.$base.dateFormater(val);
			this.birthdayPop = false;
		},
		// 证件类型
		cardTypeCheck(val) {
			this.insureInfo.cardNum = '';
			this.insureInfo.sex = '';
			this.insureInfo.birthday = '';
			this.insureInfo.cardType = val.label;
			this.cardTypePop = false;
		},

		// 获取职业类别
		async getJobDict() {
			let res = await http_getInsProfessionCodeListByCompanyId(this.$route.query.companyId);
			this.jobPopList = res;
		},
		jobCheck({ selectedOptions }) {
			let dict = selectedOptions[selectedOptions.length - 1];
			this.insureInfo.job = dict.profName;
			this.insureInfo.jobObject = dict;
			this.$emit('changejobCheck', dict);
			this.jobPop = false;
		},

		getData() {
			return new Promise(resolve => {
				this.$refs.form
					.validate()
					.then(() => {
						let data = Object.assign({ isFixedInsure: this.isFixedInsure }, this.insureInfo);
						// 在这里要做数据处理，个人的时候，解析出出生日期、性别等
						if (Mtils.validation.isIdCard(data.cardNum) && this.insureInfo.userType === '个人') {
							let idInfo = this.$base.getInfoFromIdNumber(data.cardNum);
							Object.assign(data, idInfo, {
								insType: insureUserTypeMap['个人'],
								cardType: cardTypeMap2['身份证'],
							});
							data.birthday = data.birthday.replace(/\//g, '-');
						} else {
							// Object.assign(data, {
							//     insType: insureUserTypeMap['企业'],
							//     cardType: cardTypeMap2['统一社会信用代码']
							// })
							Object.assign(data, {
								insType: insureUserTypeMap[data.userType],
								cardType: cardTypeMap2[data.cardType],
							});
						}
						resolve(data);
					})
					.catch(() => {
						Toast('请检查投人保信息');
					});
			});
		},

		// 自动填充投保人
		fillForm() {
			// if (!this.fill) {
			//     return
			// }
			// http_getLastInsured().then(res => {
			//     if (res.insName)        this.$set(this.insureInfo, 'userName', res.insName);
			//     if (res.insCredentials) this.$set(this.insureInfo, 'cardNum', res.insCredentials);
			//     if (res.insPhone)       this.$set(this.insureInfo, 'phone', res.insPhone);
			//     if (res.insAddress)     this.$set(this.insureInfo, 'address', res.insAddress);
			//     if (res.insEmail)       this.$set(this.insureInfo, 'email', res.insEmail);
			//     if (res.insType)       this.$set(this.insureInfo, 'userType', res.insType == 1 ? '个人' : '团体');
			//     if (res.cardType)       this.$set(this.insureInfo, 'email', res.insCredentialsType);
			//     if(res.insType == 2) {
			//         this.cardTypeList = groupCardType
			//         this.insureInfo.cardType = '统一社会信用代码'
			//     }else {
			//         this.cardTypeList = cardType
			//         this.insureInfo.cardType = '身份证'
			//     }

			//     this.userTypeCheck()
			// })
			this.userTypeCheck();
		},

		initBus() {
			bus.$on('getInsureData', () => {
				this.getData().then(res => {
					bus.$emit('receiveInsureData', res);
				});
			});
		},

		// 销毁bus方法
		clearBus() {
			bus.$off('getInsureData');
		},

		// 投保人姓名格式化
		nameFormatter(v) {
			return v.toLocaleUpperCase().replace(/ /g, '');
		},

		// 身份证号码格式化
		idCardNoFormatter(v) {
			return String(v).toLocaleUpperCase().replace(/ /g, '');
		},

		// 手机号码格式化
		mobileFormatter(v) {
			return String(v).replace(/ /g, '');
		},

		// 邮箱格式化
		emailFormatter(v) {
			return String(v).replace(/ /g, '');
		},

		// 投保人名称检测
		nameCheck(val) {
			if (val[0] === '·' || val[val.length - 1] === '·') {
				return false;
			}
			return true;
		},

		// 证件号码校验方法
		cardNumCheck(val) {
			// 投保人
			if (this.insureInfo.userType === '个人') {
				if (this.insureInfo.cardType === '身份证') {
					// 处理大小写问题
					this.insureInfo.cardNum = String(val).toLocaleUpperCase();
					if (this.insureInfo.cardType === '身份证' && Mtils.validation.isIdCard(this.insureInfo.cardNum)) {
						// 计算
						let info = this.$base.getInfoFromIdNumber(val);
						this.insureInfo.birthday = info.birthday;
						this.insureInfo.sex = info.sex;
						this.currentDate = new Date(info.birthday);
					}
					return Mtils.validation.isIdCard(val);
				}
				if (this.insureInfo.cardType === '护照') {
					return regular.passport.test(val) && !regular.repeatReg7.test(val) && !regular.sequentialReg7.test(val);
				}
				if (this.insureInfo.cardType === '军官证') {
					return regular.army.test(val);
				}
				if (this.insureInfo.cardType === '港澳回乡证') {
					return regular.hkCard.test(val);
				}
				if (this.insureInfo.cardType === '台胞证') {
					return regular.twCard.test(val);
				}
			} else if (this.insureInfo.userType === '团体' || this.insureInfo.userType === '企业') {
				if (this.insureInfo.groupCardType === '统一社会信用代码') {
					return Mtils.validation.isCreditCode(val);
				}
			}
			return Mtils.validation.isIdCard(val) || Mtils.validation.isCreditCode(val);
		},

		// 社会统一信用代码校验
		creditCode(val) {
			return Mtils.validation.isCreditCode(val);
		},

		// 手机号码校验
		phoneCheck(val) {
			return regular.phone.test(val);
		},

		// 邮箱校验
		emailCheck(val) {
			if (val) {
				return regular.email.test(val);
			}
			return true;
		},
	},
};
</script>
